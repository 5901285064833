<template>
  <div class="hetong">
    <van-nav-bar
      :title="'Contract'"
      @click-left="$router.go(-1)"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <img class="hetongUrl" :src="url" alt="">
    </div>
  </div>
</template>

<script>
import './css/my.css'
export default {
  name: 'hetong',
  data () {
    return {
      // 图片
      url: ''
    }
  },
  created () {
    this.url = `https://file.${this.getMainHost()}/${this.$route.query.url}`
  },
  methods: {
    // 获取二级域名
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')
      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    }
  }
}
</script>

<style  scoped>
.container {
}
.hetongUrl {
  width: 500px;
  height: 100%;
}
</style>
